import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Logger } from '../services/logger/logger';
import { LOGGER } from '../consts/log.enum';
import { MESSAGE_ERROR_SERVICE_UNAVAILABLE } from '../consts/messages.const';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(private logger: Logger) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse && event.body) {
          this.logger.log(`%cService ${request.url} result`, LOGGER.requestResult, event.body);
        }
      }),
      catchError((res) => {
        // для 500 ошибок - не выводить лог
        if (!(res instanceof HttpErrorResponse) || (res.status && Math.round(res.status / 100) === 5) || !res.error) {
          res.error = {
            error: {
              message: MESSAGE_ERROR_SERVICE_UNAVAILABLE,
            },
          };
        }

        return throwError(() => res.error);
      }),
    ) as Observable<HttpEvent<unknown>>;
  }
}
