import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { DynamicModule } from 'ng-dynamic-component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';

import { NzTransferSelectSortingModule } from '../nz-transfer-select-sorting';
import { NzDatePickerMaskModule } from '../nz-date-picker-mask';
import { JournalComponent } from './journal/journal.component';
import { JournalOptionComponent } from './journal-option/journal-option.component';
import { JournalFilterComponent } from './journal-filter/journal-filter.component';
import { ExcelService } from './services/excel.service';
import { JournalService } from './services/journal.service';
import { FilterDateComponent } from './filters/filter-date/filter-date.component';
import { FilterTextComponent } from './filters/filter-text/filter-text.component';
import { FilterSelectComponent } from './filters/filter-select/filter-select.component';
import { FilterMultiSelectComponent } from './filters/filter-multiselect/filter-multiselect.component';
import { FilterCheckboxComponent } from './filters/filter-checkbox/filter-checkbox.component';
import { JournalFilterColumnComponent } from './journal-filter-column/journal-filter-column.component';
import { JournalColumnSortingComponent } from './column/journal-column-sorting.component';
import { JournalResizeColumnDirective } from './directives/journal-resize-column.directive';
import { JournalManageComponent } from './journal-manage/journal-manage.component';
import { FilterDateTimeComponent } from './filters/filter-datetime/filter-datetime.component';
import { FilterNumberComponent } from './filters/filter-number/filter-number.component';
import { FilterReferenceComponent } from './filters/filter-reference/filter-reference.component';
import { FilterConditionComponent } from './filters/filter-condition/filter-condition.component';
import { JournalCheckedRadioComponent } from './journal-checked-radio/journal-checked-radio.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzInputModule,
    NzGridModule,
    NzSelectModule,
    NzSwitchModule,
    NzDatePickerModule,
    NzTableModule,
    NzCheckboxModule,
    NzToolTipModule,
    NzIconModule,
    NzFormModule,
    NzModalModule,
    NzDatePickerModule,
    DynamicModule,
    DragDropModule,
    NzCollapseModule,
    NzDatePickerMaskModule,
    NzTransferSelectSortingModule,
    NzAlertModule,
    NzInputNumberModule,
    NzRadioModule,
  ],
  declarations: [
    JournalComponent,
    JournalManageComponent,
    JournalOptionComponent,
    JournalFilterComponent,
    JournalFilterColumnComponent,
    JournalColumnSortingComponent,
    JournalResizeColumnDirective,
    JournalCheckedRadioComponent,
    FilterConditionComponent,
    FilterTextComponent,
    FilterNumberComponent,
    FilterDateComponent,
    FilterDateTimeComponent,
    FilterSelectComponent,
    FilterMultiSelectComponent,
    FilterCheckboxComponent,
    FilterReferenceComponent,
  ],
  exports: [
    // prettier-ignore
    JournalComponent,
    JournalColumnSortingComponent,
  ],
  providers: [
    // prettier-ignore
    JournalService,
    ExcelService,
  ],
})
export class JournalModule {}
