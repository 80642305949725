import { Injectable } from '@angular/core';
import { CanMatch, Route, UrlSegment } from '@angular/router';
import { ProfileService } from 'src/app/shared/services/app/profile.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CanMatchHelp implements CanMatch {
  constructor(private profileService: ProfileService) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return !this.profileService.isLoggedIn();
  }
}
