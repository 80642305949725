import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAuthLogin } from 'src/app/shared/types/auth.type';
import { set } from 'lodash-es';
import { AUTH_TOKEN_SKIP } from 'src/app/shared/consts/http.const';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private skipAuthToken: HttpHeaders;

  constructor(private http: HttpClient) {
    this.skipAuthToken = new HttpHeaders(set({}, AUTH_TOKEN_SKIP, 1));
  }

  /**
   * Аутентификация
   */
  login(username: string, password: string) {
    return this.http.post<IAuthLogin>(
      '/auth/signIn/basic',
      { username, password },
      {
        headers: this.skipAuthToken,
      },
    );
  }

  /**
   * Обновление access token
   * @param refreshToken
   */
  refreshToken(refreshToken: string) {
    return this.http.post<IAuthLogin>(
      '/auth/session/refresh',
      { refreshToken },
      {
        headers: this.skipAuthToken,
      },
    );
  }

  /**
   * Выход, удаление сессии
   */
  logout() {
    return this.http.post<IAuthLogin>('/auth/session/close', {});
  }
}
