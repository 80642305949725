import { JournalFilterConditionType } from './journal-filter.type';

export interface IJournalResult<T> {
  pagination: {
    // количество записей
    totalCount: number;
    // имеются ли следующие страницы
    hasNext: boolean;
    // номер следующей страницы
    nextNum: number | null;
    // имеются ли предыдущие страницы
    hasPrev: boolean;
    // номер предыдущей страницы
    prevNum: number | null;
    // номер текущей страницы
    page: number;
    // список страниц (вокруг текущей страницы)
    pageRange: number[];
  };
  items: T[];
}

export interface IJournalQuery {
  // номер страницы
  page: number;
  // количество записей на страницу
  perPage: number;
  // фильтры
  filters: IJournalQueryFilter[];
  // колонки
  columns: IJournalQueryColumn[];
  // сортировка
  order: IJournalQuerySort[] | null;
}

export interface IJournalQueryFilter {
  field: string;
  operator: JournalFilterConditionType;
  values: string[] | number[] | null;
}

export type IJournalQueryColumn = string;

export const JOURNAL_QUERY_SORT_ASC = 'ASC';
export const JOURNAL_QUERY_SORT_DESC = 'DESC';

export interface IJournalQuerySort {
  field: string;
  operator: typeof JOURNAL_QUERY_SORT_ASC | typeof JOURNAL_QUERY_SORT_DESC;
}

export const JOURNAL_QUERY_SORT_MAP = {
  ASC: 'ascend',
  DESC: 'descend',
};
