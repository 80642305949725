<section class="page-404">
  <div class="page-404__container">
    <div class="container">
      <div class="row">
        <div class="page-404__bg">
          <h1 class="text-center">Произошла какая-то ошибка</h1>
        </div>

        <div class="page-404__box">
          <h3>Попробуйте обновить<br />или нажмите на кнопку "Вернуться назад"</h3>

          <a (click)="window.location.reload()" class="link-404">Обновить</a>
          <a (click)="window.history.back()" class="link-404">Вернуться назад</a>
        </div>
      </div>
    </div>
  </div>
</section>
